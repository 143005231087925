// Copyright 2020 Noah Kennedy Larkspur CA.

/**
 * @fileoverview List of building items for Massing Configurator
 *
 * @author noahskennedy@gmail.com (Noah Kennedy)
 */

import React, { Component } from 'react';
import SegmentListItem from './segment-list-item';

class SegmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seglist: this.props.seglist,
			editable: this.props.editable
		};
		this.handleNewHeight = this.handleNewHeight.bind(this);
		this.handleHeightIncrement = this.handleHeightIncrement.bind(this);
	}

	/**
	 * change height value for a segment and update state
	 *
	 * @param i -- index value for the segment in seglist
	 *        newVal -- new height value for that segment
	 *
	 * @return none
	 **/
	handleNewHeight(index, height) {
		var heightNumber = 0;
		if (typeof height === 'string') {
			heightNumber = parseFloat(height);
		} else heightNumber = height;

		const temp_seglist = this.props.seglist;

		// find the segment in the displaylist that matches the index of the edited item
		var indexVal = -1;
		for (var i = 0; i < temp_seglist.length; i++) {
			// if (temp_seglist[i].index === index) {
			if (i === index) {
				indexVal = i;
			}
		}

		temp_seglist[indexVal].minHeight = heightNumber;
		this.setState({ seglist: temp_seglist });

		// this will update the props data structure, which should always be the seglist
		// the index here should be relative to the 'master' seglist in mc-container
		this.props.onSeglistUpdate(temp_seglist[indexVal].index, height);
	}

	/**
	 * change height value by an incremental amount, as from increment/decrement button in UI
	 * not currently used- the height boxes are now directly editable
	 *
	 * @param e -- event handler
	 *        i -- index value for the segment in seglist
	 *        amt -- value to increment/decrement that segment
	 *
	 * @return none
	 **/
	handleHeightIncrement(e, i, amt) {
		var inc_amt = amt;

		if (e.ctrlKey) {
			if (amt > 0) {
				inc_amt = 10;
			} else {
				inc_amt = -10;
			}
		}
		if (e.shiftKey) inc_amt = 0.1;

		if (e.altKey) {
			if (amt > 0) {
				inc_amt = 100;
			} else {
				inc_amt = -100;
			}
		}

		const temp_seglist = this.props.seglist;
		temp_seglist[i].minHeight += inc_amt;
		this.setState({ seglist: temp_seglist });

		// the index here should be relative to the 'master' seglist in mc-container
		// NK: this next line has not been tested
		this.props.onSeglistUpdate(
			temp_seglist[i].index,
			temp_seglist[i].minHeight
		);
	}

	render() {
		return (
			<React.Fragment>
				{this.props.seglist.map((segment, index) => (
					<SegmentListItem
						key={index}
						onNewHeight={this.handleNewHeight}
						onHeightIncrement={this.handleHeightIncrement}
						// index={segment.index}
						index={index}
						id={segment.id}
						height={segment.minHeight}
						editable={this.props.editable}
					/>
				))}
			</React.Fragment>
		);
	}
}

export default SegmentList;
