// Copyright 2020 Noah Kennedy Larkspur CA.

/**
 * @fileoverview Container component for Massing Configurator
 *
 * @author noahskennedy@gmail.com (Noah Kennedy)
 */
import React, { Component } from 'react';
import {
  readDxfGeometry,
  segmentListToPolylines,
  segmentListToFaces,
  faceListToFaces,
  dxfHeader,
  dxfFooter,
  downloadAsTextFile
} from './../services/dxf-file-service';
import SegmentList from './segment-list';
import Viewport from './viewport';
import ScenarioOpsControls from './scenario-ops-controls';
import { fastcastService } from 'agol-api';
// import { FaDownload } from "react-icons/all-files/fa/FaDownload"


var FastcastService = new fastcastService();

class MassConfigComponent extends Component {
  mcContainerId = '';
  displayList = [];

  constructor(props) {
    super(props);

    // this.downloadNewInputDXF = this.downloadNewInputDXF.bind(this);
    // this.downloadOutputDXF = this.downloadOutputDXF.bind(this);

    if (props.calculationBK === 'null_value') {
      this.state.editable = true;
      this.mcContainerId = 'mc-container';
    } else {
      // DEBUG REMOVE FREEZING
      // this.state.editable = false;
      // this.mcContainerId = "mc-container-readonly"
      this.state.editable = true;
      this.mcContainerId = 'mc-container';
    }
  }

  state = {
    dataset: 'null dataset',
    facelist: [],
    seglist: [],
    validFaceList: false,
    validSegList: false,
    displaylist: [], // the subset of the seglist to show on the MC list
    sceneCenter: { x: 0, y: 0, z: 0 },
    sceneMin: { x: 0, y: 0, z: 0 },
    sceneMax: { x: 0, y: 0, z: 0 },
    cameraPosition: { x: 1000, y: 1000, z: 1000 },
    projectBK: this.props.projectBK,
    calculationBK: this.props.calculationBK,
    accessToken: this.props.accessToken,
    clientBK: this.props.clientBK,
    scenarioName: '',
    editable: true,
    inputFileName: '',
    testInputFileName: '',
    testInputString: '',
    filterList: false,
    adjustCamera: false,
    loadingUnderway: false,
    calcUnderway: false
  };

  currentName = this.state.scenarioName;



  updateFilter() {
    if (this.state.filterList) {
      this.displayList = this.state.seglist.filter(
        (f) => f.id.indexOf('static_') === -1
      );
    } else {
      this.displayList = this.state.seglist;
    }
  }

  componentDidMount() {
    if (this.props.calculationBK === 'null_value') {
      this.setState({ editable: true });
    }
    // DEBUG REMOVE FREEZING
    // this.setState({ editable: false })
    else this.setState({ editable: true });

    this.displayList = this.state.seglist;
    this.setState({ filterList: false });
  }

  componentDidUpdate() {
    if (this.state.editable) {
      this.mcContainerId = 'mc-container';
    } else {
      // DEBUG: REMOVE FREEZING AFTER OPERATION
      // this.mcContainerId = "mc-container-readonly"
      this.mcContainerId = 'mc-container';
    }

    this.state.loadingUnderway = false;

    //   if (this.state.seglist[0].id != "EMPTY_SEGLIST") {
    //     this.setState({ validSegList: true })
    //   } else this.setState({ validSegList: false });


    // if (this.state.facelist[0].id != "EMPTY_FACELIST") {
    //   this.setState({ validFaceList: true })
    // } else this.setState({ validFaceList: false });


    if (this.state.seglist.length > 0) {
      document.getElementById('filter-control').display = 'block';
    } else document.getElementById('filter-control').display = 'none';
  }


  /**
   * Toggle whether to display a filtered or unfiltered building list
   * Does not affect what is seen in viewport
   *
   * @param none
   *
   * @return none
   **/
  toggleFilterView() {
    var desired_state = document.getElementById('filter-checkbox').checked;
    var prev_state = this.state.filterList;
    this.setState({ filterList: desired_state });

    if (desired_state) {
      this.displayList = this.state.seglist.filter(
        (f) => f.id.indexOf('static_') === -1
      );
      this.setState({ filterList: true });
    } else {
      this.displayList = this.state.seglist;
      this.setState({ filterList: false });
    }
  }

  /**
   * Toggle whether to be in adjust camera mode or not
   *
   * @param none
   *
   * @return none
   **/
  toggleAdjustCamera() {
    var desired_state = document.getElementById('view-toggle-checkbox').checked;
    var prev_state = this.state.adjustCamera;
    this.setState({ adjustCamera: desired_state });
  }


  /**
   * sets scene center to center point of dataset
   *
   * @param none
   *
   * @return none
   **/
  resetSceneCenter() {

    if (this.state.seglist.length === 0) {
      return;
    }

    let xMax = -999999999999999999;
    let xMin = 999999999999999999;
    let yMax = -999999999999999999;
    let yMin = 999999999999999999;
    let zMax = -999999999999999999;
    let zMin = 999999999999999999;

    const temp_center = { x: 0, y: 0, z: 100 };
    const temp_position = { x: 0, y: 0, z: 0 };

    // go through segments
    let i = 0;
    let j = 0;

    // if there is a seglist, run through it...
    if (this.state.seglist[0].id != "EMPTY_SEGLIST") {
      this.setState({ validSegList: true })
      for (i = 0; i < this.state.seglist.length; i++) {
        for (
          j = 0;
          j < this.state.seglist[i].geometry.vertices.length;
          j++
        ) {
          // todo: clean code per F example below

          xMax = Math.max(xMax, this.state.seglist[i].geometry.vertices[j].x);
          yMax = Math.max(yMax, this.state.seglist[i].geometry.vertices[j].y);
          xMin = Math.min(xMin, this.state.seglist[i].geometry.vertices[j].x);
          yMin = Math.min(yMin, this.state.seglist[i].geometry.vertices[j].y);
          zMax = Math.max(zMax, this.state.seglist[i].geometry.vertices[j].z);
          zMin = Math.min(zMin, this.state.seglist[i].geometry.vertices[j].z);

          // if (this.state.seglist[i].geometry.vertices[j].x > xMax)
          //   xMax = this.state.seglist[i].geometry.vertices[j].x;
          // if (this.state.seglist[i].geometry.vertices[j].x < xMin)
          //   xMin = this.state.seglist[i].geometry.vertices[j].x;
          // if (this.state.seglist[i].geometry.vertices[j].y > yMax)
          //   yMax = this.state.seglist[i].geometry.vertices[j].y;
          // if (this.state.seglist[i].geometry.vertices[j].y < yMin)
          //   yMin = this.state.seglist[i].geometry.vertices[j].y;
        }
      }
    } else {
      console.log("resetSceneCenter: empty segment list");
      this.setState({ validSegList: false })
    }

    // if there is a facelist, run through it...
    if (this.state.facelist[0].id != "EMPTY_FACELIST") {
      this.setState({ validFaceList: true });
      for (i = 0; i < this.state.facelist.length; i++) {
        let F = this.state.facelist[i];

        xMin = Math.min(xMin, F.pt0[0], F.pt1[0], F.pt2[0], F.pt3[0]);
        xMax = Math.max(xMax, F.pt0[0], F.pt1[0], F.pt2[0], F.pt3[0]);
        yMin = Math.min(yMin, F.pt0[1], F.pt1[1], F.pt2[1], F.pt3[1]);
        yMax = Math.max(yMax, F.pt0[1], F.pt1[1], F.pt2[1], F.pt3[1]);
        zMin = Math.min(zMin, F.pt0[2], F.pt1[2], F.pt2[2], F.pt3[2]);
        zMax = Math.max(zMax, F.pt0[2], F.pt1[2], F.pt2[2], F.pt3[2]);
      }
    } else {
      console.log("resetSceneCenter: empty face list");
      this.setState({ validFaceList: false });
    }

    temp_center.x = (xMax + xMin) / 2;
    temp_center.y = (yMax + yMin) / 2;
    temp_center.z = -40; // tweaked for Stonestown
    temp_center.z = 300;


    temp_position.x = temp_center.x + 1200;
    temp_position.y = temp_center.y - 3000;
    temp_position.z = temp_center.z + 550; // tweaked for Stonestown

    // temp_position.x = temp_center.x + 12200;
    // temp_position.y = temp_center.y - 5000;
    // temp_position.z = temp_center.z + 12550; // tweaked for Boston Model

    // note as soon as the camerPosition changes, the camera changes automatically
    this.setState({ cameraPosition: temp_position });
    this.setState({ sceneCenter: temp_center });
    this.setState({ sceneMin: { x: xMin, y: yMin, z: zMin } });
    this.setState({ sceneMax: { x: xMax, y: yMax, z: zMax } });
    console.log("scene bounds", this.state.sceneMin, this.state.sceneMax)
  }

  /**
   * updates a segment within seglist values. Called by onSeglistUpdate in seglist component
   *
   * @param new_seglist -- new segment list that should overwrite existing
   *
   * @return none
   **/
  handleSeglistUpdate(i, newHeight) {
    let writeHeightVal = newHeight;
    if (typeof newHeight === 'string')
      writeHeightVal = parseFloat(newHeight);

    var newSegList = this.state.seglist;
    newSegList[i].minHeight = writeHeightVal;
    this.setState({ seglist: newSegList });
  }

  // TODO: this should really be a seglist method
  indexSegList() {
    for (var i = 0; i < this.state.seglist.length; i++) {
      this.state.seglist[i].index = i;
    }
  }

  /**
   * updates a segment within seglist values. Called by onChangeCamera in seglist component
   *
   * @param newPosition - new x, y, z coordinates for camera
   * 
   * @param newLookAt - new x, y, z coordinates for scene center
   *
   * @return none
   **/
  handleChangeCamera(newPosition, newLookAt) {
    this.state.cameraPosition = { "x": newPosition.x, "y": newPosition.y, "z": newPosition.z }
    this.state.sceneCenter = { "x": newLookAt.x, "y": newLookAt.y, "z": newLookAt.z }
  }

  // TODO: this should really be a seglist method
  indexSegList() {
    for (var i = 0; i < this.state.seglist.length; i++) {
      this.state.seglist[i].index = i;
    }
  }

  /**
   * lets user select a DXF file to load, and then loads it
   *
   * @param e -- event handler
   *
   * @return none
   **/
  loadSegmentDxfFile = async (e) => {

    this.setState.seglist = [];
    this.setState.facelist = [];
    let dxfGeomPackage;
    // let temp_seglist;

    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      this.dxfString = text;
      dxfGeomPackage = readDxfGeometry(text);
      // temp_seglist = dxfGeomPackage.seglist;

      // update state.seglist:
      this.setState({ seglist: dxfGeomPackage.seglist });

      this.indexSegList();

      // update displaylist:
      this.displayList = this.state.seglist;
      // turn filtering off:
      if (this.state.filterList) this.setState({ filterList: false });

      // update state.facelist:
      this.setState({ facelist: dxfGeomPackage.facelist });

      // reset state.sceneCenter and state.cameraPosition
      this.resetSceneCenter();
    };
    reader.readAsText(e.target.files[0]);
    this.setState({ loadingUnderway: false });
  };

  /**
   *  Downloads the current scenario as a DXF file to user's local drive
   *  The DXF file produced is a reusable copy of the current scenario description.
   *  Segments will be written as POLYLINEs with current values.
   *  3DFACES will be reproduced in the downloaded file without changes
   * 
   * @param scenario_name {str} -- root string for downloaded file
   *
   * @return none
   **/
  downloadNewInputDXF(scenario_name) {
    let dxf = dxfHeader();

    if (this.state.validSegList) {
      dxf += segmentListToPolylines(this.state.seglist);
    }

    if (this.state.validFaceList) {
      dxf += faceListToFaces(this.state.facelist);
    }

    dxf += dxfFooter();
    downloadAsTextFile(scenario_name + '.dxf', dxf);
  }

  /**
   * returns the current scenario as a DXF string.
   * The DXF string produced contains only 3DFACE's.
   * Its primary target is fastcast service, but can also be read by MC and AutoCAD.
   * 
   * The returned file should be identical to the Output file downloaded from the same scenario description.
   * 
   * @param none
   * 
   * @return none
   **/
  outputDXF() {

    let dxf = dxfHeader();

    if (this.state.validSegList) {
      dxf += segmentListToFaces(this.state.seglist);
    }

    if (this.state.validFaceList) {
      dxf += faceListToFaces(this.state.facelist);
    }

    dxf += dxfFooter();

    return dxf;
  }

  /**
   * Downloads the current scenario (segments as well as faces) as a DXF file to user's local drive
   * The DXF file produced has the scenario description only in 3DFACE format,
   * so it can be read by AutoCAD but not by MC.
   * 
   * The downloaded file should be identical to the string sent to fastcast for processing
   * 
   * @param scenario_name {str}: suggested root for file name 
   *
   * @return none
   **/
  downloadOutputDXF(scenario_name) {
    let dxf = this.outputDXF();
    downloadAsTextFile(scenario_name + '.dxf', dxf);
  }


  /**
   * initiates a fan calculation based on the data
   * held in the mc-container.state
   *
   * params e: event handler
   * params dxf {string}: seglist contained within a DXF file
   *
   * @return none
   **/
  calculateFan(scenario_name) {
    // disable Calculate Fan button
    this.setState({ calcUnderway: true });

    let dxf = this.outputDXF();

    // Calls /api/calculations?projectBusinessKey
    FastcastService.postFanCalculation(
      this.props.accessToken.substring(7),
      this.state.projectBK,
      scenario_name,
      0,
      '{"parcelId":"3722022","centroidHeight":27.8,"centroidLongitude":-122.479742,"centroidLatitude":37.7281281,"address":{"street":"3251 20TH AVENUE","city":"San Francisco","state":"CA"}}'
    )
      .then((response) => {
        console.log('<1> postFanCalculation succeeded:', response);

        var calcBK = response.data.businessKey;
        this.setState({ calculationBK: calcBK });
        // DEBUG REMOVE FREEZING
        // this.setState({ editable: false });
        this.props.onUpdate(this.state.calculationBK);

        // DEBUG ALTERNATIVE INPUT FILE STREAM
        var fileStream = this.state.testInputString;
        if (fileStream === '') fileStream = dxf;

        // post the input DXF file to the server
        // Calls /api/calculations/${calculationBusinessKey}/artifact
        FastcastService.postParcelDxf(
          this.props.accessToken.substring(7),
          calcBK,
          fileStream
        )
          .then((response) => {
            console.log('<2> postParcelDxf succeeded:', response);

            // generate a new shadow fan
            FastcastService.queueFanCalculation(
              this.props.accessToken.substring(7),
              calcBK,
              this.state.projectBK,
              this.state.clientBK,
              0
            )
              .then((response) => {
                console.log('<3> queueFanCalculation succeeded:', response);

                // retrieve the new shadow fan
                FastcastService.getCalculationDxf(
                  this.props.accessToken.substring(7),
                  calcBK
                )
                  .then((response) => {
                    console.log('<4> getCalculationDxf succeeded:', response);

                    // since this was successful, put dialog in read-only mode
                    // DEBUG: REMOVE FREEZING
                    // this.setState({ editable: false });

                    // enable Calculate Fan button
                    this.setState({ calcUnderway: false });

                  })
                  .catch((error) => {
                    console.log('!<4> getCalculationDxf failed: ', error);
                    this.setState({ calcUnderway: false });
                  });
              })
              .catch((error) => {
                console.log('!<3> computeCalculation failed: ', error);
                this.setState({ calcUnderway: false });
              });
          })
          .catch((error) => {
            console.log('!<2> postParcelDxf failed: ', error);
            this.setState({ calcUnderway: false });
          });
      })
      .catch((error) => {
        console.log('postFanCalculation failed: ', error, this.state.scenarioName);
        alert('!<1> Access denied, check credentials');
        this.setState({ calcUnderway: false });
      });
  }

  /**
   * hides massing configurator by setting projectBK to empty string
   * hiding this way makes rendering the parent app without a live MC
   * very fast
   *
   * params (none)
   *
   * @return none
   **/
  dismissMassingConfigurator() {
    this.setState({ projectBK: '' });
  }


  // variables that allow the formatting of DXF Input button to change
  inputClassName = 'custom-file-upload btn btn-sm btn-primary';
  labelString = 'Load DXF Input File';

  render() {


    if (this.state.inputFileName != '') {
      this.inputClassName =
        'custom-file-upload btn btn-sm btn-outline-secondary';
      this.labelString = 'Change';
    }

    if (this.state.loadingUnderway) {
      this.inputClassName =
        'custom-file-upload btn btn-sm btn-info';
      this.labelString = 'loading...';
    }

    // DEBUG: REMOVE FREEZING AFTER OPERATION
    // if (!this.state.editable) {
    //   this.inputClassName = "custom-file-upload-readonly"
    //   this.labelString = "";
    // }

    // render only if a valid projectBK:
    if (this.state.projectBK != '') {
      return (
        <React.Fragment>
          <div
            id={this.mcContainerId}
            className='d-flex flex-row flex-wrap centered'

          >
            <div id='mc-header'>
              <label className={this.inputClassName}>
                {this.labelString}
                <input
                  type='file'
                  placeholder='choose a file dude'
                  onChange={(e) => {
                    this.inputClassName = 'custom-file-upload btn btn-sm btn-outline-secondary';
                    this.loadSegmentDxfFile(e);
                    this.setState({ loadingUnderway: true });
                    this.setState({ inputFileName: '  ' + e.target.files[0].name });
                    this.setState({ filterList: false });
                  }}
                />
              </label>
              <span id='fileNameTitle'>
                {this.state.inputFileName}
              </span>

              <span
                id='filter-control'
                hidden={this.state.seglist.length === 0 || this.state.seglist[0].id === "EMPTY_SEGLIST"}
              >
                <input
                  type='checkbox'
                  id='filter-checkbox'
                  checked={this.state.filterList}
                  onClick={() => this.toggleFilterView()}
                />
                <label id='filter-label' className='btn'>
                  Filter Display List
								</label>
              </span>

              <span
                id='view-toggle'
                hidden={this.state.seglist.length === 0 && this.state.facelist.length === 0}>
                <input
                  type='checkbox'
                  id='view-toggle-checkbox'
                  checked={this.state.adjustCamera}
                  onClick={() => this.toggleAdjustCamera()}
                />
                <label id='filter-label' className='btn'>
                  Adjust Camera
								</label>
              </span>

              <button
                type='button'
                className='close'
                aria-label='Close'
                onClick={() =>
                  this.dismissMassingConfigurator()
                }
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>

            <div id='mc-left-column'>
              <Viewport
                key='canvas'
                onChangeCamera={
                  this.handleChangeCamera.bind(this)
                }
                sceneCenter={this.state.sceneCenter}
                sceneMin={this.state.sceneMin}
                sceneMax={this.state.sceneMax}
                cameraPosition={this.state.cameraPosition}
                seglist={this.state.seglist}
                facelist={this.state.facelist}
                loadingUnderway={this.state.loadingUnderway}
                adjustCamera={this.state.adjustCamera}
              />
            </div>

            <div
              id='mc-right-column'
              className='d-flex flex-column'
            >
              <div
                id='mc-seg-list'
                className='d-flex flex-row flex-wrap'
              >
                <SegmentList
                  key='segmentlist'
                  onSeglistUpdate={
                    this.handleSeglistUpdate.bind(this)
                  }
                  seglist={this.displayList}
                  editable={this.state.editable}
                  filterList={this.state.filterList}
                />
              </div>


              <ScenarioOpsControls
                dataLoaded={this.state.validSegList || this.state.validFaceList}
                calcUnderway={this.state.calcUnderway}
                onCalculateFan={this.calculateFan.bind(this)}
                onNewInput={this.downloadNewInputDXF.bind(this)}
                onOutputDXF={this.downloadOutputDXF.bind(this)}
              />

            </div>
          </div>
        </React.Fragment >
      );
    } else {
      // no valid projectBK, don't render anything:
      console.log('mc-container, no valid projectBK value', this.state.projectBK);
      return null;
    }
  }
}

export default MassConfigComponent;
