// Copyright 2020 Noah Kennedy Larkspur CA.

/**
 * @fileoverview 3D viewport component for Massing Configurator
 *
 * @author noahskennedy@gmail.com (Noah Kennedy)
 */

import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

class Viewport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scene: new THREE.Scene(),
      // Initialize camera settings: FOV, front and back plane, etc.
      camera: new THREE.PerspectiveCamera(14, window.innerWidth / window.innerHeight, 10, 20000),
      renderer: new THREE.WebGLRenderer(),
      animRenderer: new THREE.WebGLRenderer(),
      controls: null,
      orbiting: false, // whether in orbit mode or render mode
    };
  }

  componentDidMount() {
    this.setupCanvas();
    this.setState({ controls: new OrbitControls(this.state.camera, this.state.renderer.domElement) })
  }

  componentDidUpdate() {
    this.reviseCanvas();
  }

  /**
   * convert a pline object into a threejs shape
   *
   * @param {l} a pline object
   *
   * @return {shape} threejs shape
   **/
  plineToShape(l) {
    var ptArray = [];

    // load 3D points into array of 2D points
    for (var i = 0; i < l.length; i++) {
      var p = new THREE.Vector2(l[i].x, l[i].y);
      if (i === 0) {
        ptArray = [p];
      } else {
        ptArray.push(p);
      }
    }

    var shape = new THREE.Shape(ptArray);
    return shape;
  } // end plineToShape

  /**
   * add threejs light objects to the scene for rendering
   *
   * @param none
   *
   * @return none
   **/
  addLights() {
    var light1 = new THREE.PointLight(0xeeffff);
    var light2 = new THREE.PointLight(0xff4444);
    var light3 = new THREE.PointLight(0xffffff);
    var light4 = new THREE.PointLight(0xffffff);

    light1.position.set(
      this.props.sceneCenter.x + 300,
      this.props.sceneCenter.y - 600,
      this.props.sceneCenter.z + 200
    );
    light2.position.set(
      this.props.sceneCenter.x + 200,
      this.props.sceneCenter.y - 200,
      this.props.sceneCenter.z + 200
    );
    light3.position.set(
      this.props.sceneCenter.x + 0,
      this.props.sceneCenter.y - 400,
      this.props.sceneCenter.z + 400
    );
    light4.position.set(
      this.props.sceneCenter.x + 200,
      this.props.sceneCenter.y - 200,
      this.props.sceneCenter.z + 2000
    );

    this.state.scene.add(light1);
    this.state.scene.add(light2);
    this.state.scene.add(light3);
    this.state.scene.add(light4);

  }

  /**
   *  draw the list of 3DFACE's. If camera is being adjusted, these will appear as a bounding box
   *
   * @param none
   *
   * @return none
   **/
  addFaces(boxmode) {

    const fl = this.props.facelist;

    if (fl[0]) {
      if (fl[0].id === "EMPTY_FACELIST") {
        return null;
      }

      let material = new THREE.MeshBasicMaterial({ color: 0xffff00, shininess: 1, transparent: true, opacity: 0.3, side: THREE.DoubleSide });
      let wireframe_material = new THREE.MeshBasicMaterial({ color: 0xffff00, shininess: 1, transparent: true, opacity: 0.7, side: THREE.DoubleSide, wireframe: true });

      // short-circuit this if boxmode is true
      if (boxmode) {
        let min = new THREE.Vector3(9999999999999999, 9999999999999, 9999999999999);
        let max = new THREE.Vector3(-9999999999999999, -9999999999999, -9999999999999);
        // run through all the faces to find min/max
        for (let i = 0; i < fl.length; i++) {
          let F = fl[i];

          min.x = Math.min(min.x, F.pt0[0], F.pt1[0], F.pt2[0], F.pt3[0]);
          max.x = Math.max(max.x, F.pt0[0], F.pt1[0], F.pt2[0], F.pt3[0]);
          min.y = Math.min(min.y, F.pt0[1], F.pt1[1], F.pt2[1], F.pt3[1]);
          max.y = Math.max(max.y, F.pt0[1], F.pt1[1], F.pt2[1], F.pt3[1]);
          min.z = Math.min(min.z, F.pt0[2], F.pt1[2], F.pt2[2], F.pt3[2]);
          max.z = Math.max(max.z, F.pt0[2], F.pt1[2], F.pt2[2], F.pt3[2]);
        }

        // add a bounding box for the faces:
        const boundingBox = new THREE.Box3(min, max);
        const helper = new THREE.Box3Helper(boundingBox, 0xffff00);
        this.state.scene.add(helper);

        return;
      }

      // for each face in the face list:
      for (let i = 0; i < fl.length; i++) {
        let F = fl[i];
        let geometry = new THREE.BufferGeometry();

        let pt0 = new THREE.Vector3(F.pt0[0], F.pt0[1], F.pt0[2]);
        let pt1 = new THREE.Vector3(F.pt1[0], F.pt1[1], F.pt1[2]);
        let pt2 = new THREE.Vector3(F.pt2[0], F.pt2[1], F.pt2[2]);
        let pt3 = new THREE.Vector3(F.pt3[0], F.pt3[1], F.pt3[2]);

        if (pt2.x != pt3.x || pt2.y != pt3.y || pt2.z != pt3.z) {
          const vertices = new Float32Array([
            // 1st triangle
            pt0.x, pt0.y, pt0.z,
            pt1.x, pt1.y, pt1.z,
            pt3.x, pt3.y, pt3.z,

            // 2nd triangle
            pt1.x, pt1.y, pt1.z,
            pt2.x, pt2.y, pt2.z,
            pt3.x, pt3.y, pt3.z
          ]);
          // establish reserved word position as (only) Buffer attribute
          geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        } else {
          // 3-vert triangle
          const vertices = new Float32Array([
            pt0.x, pt0.y, pt0.z,
            pt1.x, pt1.y, pt1.z,
            pt2.x, pt2.y, pt2.z
          ]);
          // establish reserved word position as (only) Buffer attribute
          geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        }

        const mesh = new THREE.Mesh(geometry, material);
        this.state.scene.add(mesh);
        let wf_mesh = new THREE.Mesh(geometry, wireframe_material);
        this.state.scene.add(wf_mesh);

      } // if l is not null
    } // i loop

  } // end addFaces

  /**
   *  draw the building footprints and extrusions
   *
   * @param none
   *
   * @return none
   **/
  addSegments() {
    const sl = this.props.seglist;
    const fastcastOrange = 0xffaa00;

    // empty the scene of geometry
    while (this.state.scene.children.length > 0) {
      this.state.scene.remove(this.state.scene.children[0]);
    }

    if (sl[0]) {
      if (sl[0].id === "EMPTY_SEGLIST") {
        return null;
      }
      // for each segment in the building segment list:
      for (var i = 0; i < sl.length; i++) {

        var segment_geometry = new THREE.BufferGeometry().setFromPoints(
          sl[i].geometry.vertices
        );

        // "g_" prefix indicates graphics (non-shadow casting) entity, give it a neutral color
        if (sl[i].id.startsWith('g_')) {
          sl[i].color = 0xffffff;
        }

        // "x_" prefix reserve (non-shadow casting) entity, give it a neutral color
        if (sl[i].id.startsWith('x_')) {
          sl[i].color = 0x444444;
          sl[i].minHeight = 0.0;
        }

        // "p_" prefix indicates user-defined park, give it a distinctive color
        if (sl[i].id.startsWith('p_')) {
          sl[i].color = fastcastOrange;
          sl[i].minHeight = 0.0;
        }

        // create a material reflecting this segment's recorded color
        // unless overwritten because of its prefix, this should be the layer color from the DXF input file
        var segment_material = new THREE.LineBasicMaterial({
          color: sl[i].color,
          color: 0x090909,
          linewidth: 5
        });

        // create a new shape for extruding
        var segment_line = new THREE.Line(
          segment_geometry,
          segment_material
        );
        segment_line.name = sl[i].id;
        var segment_shape = this.plineToShape(sl[i].geometry.vertices);

        // produce base geometry to render
        var extrudeSettings = {
          steps: 1,
          depth: sl[i].minHeight,
          bevelEnabled: false,
          bevelThickness: 1,
          bevelSize: 1,
          bevelOffset: 0,
          bevelSegments: 0,
        };

        var segment_minbase = new THREE.ExtrudeGeometry(
          segment_shape,
          extrudeSettings
        );

        var plate_base_material = new THREE.MeshStandardMaterial({
          color: sl[i].color,
          transparent: true,
          opacity: 0.4
        });

        // make the park more transparent
        if (sl[i].color === fastcastOrange) {
          plate_base_material.opacity = 0.2;
        }

        // this is the basic building block volume- the "min" in AltGen
        var minbase = new THREE.Mesh(
          segment_minbase,
          plate_base_material
        );
        minbase.name = segment_line.name;
        // bug: assumes the input segment was flat
        minbase.translateZ(sl[i].geometry.vertices[0].z);
        this.state.scene.add(minbase);

        // this is a wireframe outline plate of the minbase shape
        var wf_geo = new THREE.EdgesGeometry(segment_minbase);
        var wf_mat = new THREE.LineBasicMaterial({
          color: sl[i].color,
          linewidth: 2
        });
        var wf_obj = new THREE.LineSegments(wf_geo, wf_mat);

        wf_obj.translateZ(sl[i].geometry.vertices[0].z);
        this.state.scene.add(wf_obj);
      } // if sl is not null
    } // i loop

  } // end addSegments


  /**
  *  create interactive viewport with camera controls
  *
  * @param t: component ("this") who's state must be modified
  *
  * @return none
  **/
  renderAnimatedCanvas(t) {
    let renderer = this.state.renderer;
    let camera = this.state.camera;
    let scene = this.state.scene;
    let controls = this.state.controls;
    controls.target = new THREE.Vector3(this.props.sceneCenter.x, this.props.sceneCenter.y, this.props.sceneCenter.z);

    const axesHelper = new THREE.AxesHelper(100)
    axesHelper.translateX(this.props.sceneCenter.x)
    axesHelper.translateY(this.props.sceneCenter.y)
    axesHelper.translateZ(this.props.sceneCenter.z)
    this.state.scene.add(axesHelper);

    var animate = function () {
      if (t.props.adjustCamera) {
        requestAnimationFrame(animate);
        controls.update();
        render();
        // update camera position in parent object
        t.props.onChangeCamera(camera.position, t.props.sceneCenter);
      }
      else {
        return;
      }
    }

    function render() {
      renderer.render(scene, camera)
    }

    animate();
  }

  /**
   *  regenerate the view in the 3d viewport
   *
   * @param none
   *
   * @return none
   **/
  reviseCanvas() {
    // update camera settings due to new sceneCenter
    let sC = this.props.sceneCenter;
    this.state.camera.lookAt(sC.x, sC.y, sC.z);
    let cP = this.props.cameraPosition;
    this.state.camera.position.set(cP.x, cP.y, cP.z);

    // empty the scene of geometry
    while (this.state.scene.children.length > 0) {
      this.state.scene.remove(this.state.scene.children[0]);
    }

    if (this.props.seglist) this.addSegments();
    if (this.props.facelist) this.addFaces(this.props.adjustCamera);

    this.addLights();
    this.state.renderer.setClearColor(0x5599cc, 1);

    if (this.props.adjustCamera) {
      this.renderAnimatedCanvas(this);
    } else {
      this.state.camera.lookAt(this.props.sceneCenter.x,
        this.props.sceneCenter.y,
        this.props.sceneCenter.z)
      this.state.renderer.render(this.state.scene, this.state.camera);
    }
  }
  /**
   *  initialize the 3d viewport without geometry data
   *
   * @param none
   *
   * @return none
   **/
  setupCanvas() {
    const staticViewportElement = document.getElementById('staticViewport');
    this.state.renderer.setSize(600, 500);

    this.state.scene.up = new THREE.Vector3(0, 0, 1);
    this.state.camera.up = new THREE.Vector3(0, 0, 1);

    staticViewportElement.appendChild(this.state.renderer.domElement);

    this.state.camera.lookAt(
      this.props.sceneCenter.x,
      this.props.sceneCenter.y,
      this.props.sceneCenter.z
    );

    // this sets the background colors
    this.state.renderer.setClearColor(0xc8c8c8, 1);
  }

  render() {

    return (
      <React.Fragment>


        <div
          id="staticViewport"
          hidden={this.props.loadingUnderway}

          style={{
            width: 600,
            height: 500,
            backgroundColor: "#5599cc",
            marginBottom: '1px',
          }}
        />

        <div
          hidden={!this.props.loadingUnderway}

          style={{
            width: 600,
            height: 500,
            backgroundColor: "#5599cc",
            marginBottom: '1px',
            padding: 250,
            paddingTop: 200,
            paddingBottom: 0,
          }}>

          <div
            className="spinner-border d-flex justify-content-center"
            style={{
              color: "#66aadd",
              width: 100,
              height: 100,
            }}
          >
          </div>
        </div>

      </React.Fragment >
    );
  }
}

export default Viewport;
