import React, { Component } from 'react';

class ScenarioOpsControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sName: '',
    }
  }

  render() {
    return (<React.Fragment>
      <form
        id='mc-scenario-controls'
        name='mc-scenario-controls'
        className='d-flex flex-row flex-wrap'
      >
        <input
          type='text'
          placeholder={'scenario name'}
          name='scenario-name'
          style={{
            width: '100%',
            marginBottom: '1px'
          }}
          onChange={e => this.setState({ sName: e.target.value })}
        />

        <button
          className='btn btn-sm btn-primary btn-scenario-control'
          onClick={(e) => {
            this.props.onCalculateFan(this.state.sName);
            e.preventDefault();
          }}
          hidden={this.props.calcUnderway}
          disabled={this.state.sName === '' || !this.props.dataLoaded}
        >Calculate Fan</button>

        <button
          className='btn btn-sm btn-primary btn-scenario-control'
          hidden={!this.props.calcUnderway}
          disabled
        >
          <span className='spinner-grow spinner-grow-sm' />{' '} calculating...
        </button>

        <button
          className='btn btn-sm btn-secondary btn-half-control'
          onClick={(e) => {
            this.props.onNewInput(this.state.sName);
            e.preventDefault();
          }}
          disabled={this.state.sName === '' || !this.props.dataLoaded}
        >New Input</button>

        <button
          className='btn btn-sm btn-secondary btn-half-control'
          style={{ marginLeft: '4px' }}
          onClick={(e) => {
            this.props.onOutputDXF(this.state.sName);
            e.preventDefault();
          }}
          disabled={this.state.sName === '' || !this.props.dataLoaded}
        >Output DXF</button>

      </form>
    </React.Fragment >)
  }
}

export default ScenarioOpsControls;