import * as React from 'react';
import MassConfigComponent from './components/mc-container.jsx';
import './mass-config.css';
import 'bootstrap/dist/css/bootstrap.css';

interface Props {
	accessToken: string;
	projectBK: string;
	calculationBK: string;
	clientBK: String;
}

export const MassingComponent = ({
	accessToken,
	projectBK,
	calculationBK,
	clientBK,
	onUpdate
}: Props) => {
	function handleUpdate(calcBK) {
		calculationBK = calcBK;
	}

	return (
		<React.Fragment>
			<div className='Bob'>
				<MassConfigComponent
					key='tempFromApp'
					accessToken={accessToken}
					projectBK={projectBK}
					calculationBK={calculationBK}
					clientBK={clientBK}
					onUpdate={handleUpdate}
				/>
			</div>
		</React.Fragment>
	);
};
