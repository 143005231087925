// Copyright 2020 Noah Kennedy Larkspur CA.

/**
 * @fileoverview functions for reading/writing DXF files
 *                transpiled from original TSX version
 *
 * @author noahskennedy@gmail.com (Noah Kennedy)
 */

// following is used to replace spread operator from TS in JS
var __assign = function () {
	__assign =
		Object.assign ||
		function (t) {
			for (var s, i = 1, n = arguments.length; i < n; i++) {
				s = arguments[i];
				for (var p in s)
					if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
			}
			return t;
		};
	return __assign.apply(this, arguments);
};

// ********************************************************
// threejs data structures used for segment.geometry and points...
// ********************************************************
var THREE = require('three');

// a single building outline and extrusion height
var Segment = /** @class */ (function () {
	function Segment(index, id, minHeight) {
		this.color = 0x000000;
		this.id = id;
		this.minHeight = minHeight; // minHeight = height in Massing Configurator, AltGen has variable for addition alternatives
		this.index = index;
	}
	return Segment;
})();
export { Segment };

// a list of Segments
var displaySegList = new Array(Segment);
displaySegList[0].id = 'DisplaySegList';
var seglist = new Array(Segment);
seglist[0].id = 'EMPTY_SEGLIST';
export { seglist };

// structure required for faces and lists of faces
class Face {
	constructor(
		id = String,
		color = Number,
		pt0 = THREE.Vector3,
		pt1 = THREE.Vector3,
		pt2 = THREE.Vector3,
		pt3 = THREE.Vector3,
		pt4 = THREE.Vector3
	) {
		this.id = id;
		this.color = color;
		this.pt0 = pt0;
		this.pt1 = pt1;
		this.pt2 = pt2;
		this.pt3 = pt3;
	}
}

var facelist = new Array(Face);
// debugger;
facelist[0].id = 'EMPTY_FACELIST';
export { facelist };

function eqStr(s1, s2) {
	if (s1.search(s2) > -1) return true;
	return false;
}

/**
 * processes the stored buffer values in the context of reading an LWPOLYLINE
 *
 * @param  b {string}: buffered code/value pairs for an LWPOLYLINE
 *
 * @return seg {Segment}: a new segment.
 *
 *         returned null segment indicates the layername is not prepended to indicate a MC segment
 *
 */
function readLWPlineBuffer(b) {
	var lines = b.split('\n');
	const seg = new Segment(0, 'SEED_SEGMENT', 40);
	seg.geometry = new THREE.Geometry();
	const pt = [0, 0, 200];

	for (let l = 0; l < lines.length; l++) {
		const line = lines[l].split(';');
		const code = parseInt(line[0]);
		const value = line[1];

		switch (code) {
			case 8:
				if (value.startsWith('@gb_')) {
					seg.id = value.substring(2);
				} else return null;
				break;
			case 1011:
				seg.minHeight = +value;
				break;
			case 62:
				seg.color = acadColorToRGB(parseInt(value));
				break;
			case 38:
				pt[2] = +value;
				break;
			case 10:
				pt[0] = +value;
				break;
			case 20:
				pt[1] = +value;
				// 20's always seem to come last, potential for bugs here...
				var point = new THREE.Vector3(pt[0], pt[1], pt[2]);
				if (seg.id !== 'RECORDED') seg.geometry.vertices.push(point);
				break;
		}
	}
	return seg;
}

/**
 * processes the stored buffer values in the context of reading a regular POLYLINE
 *
 * @param  b {string}: buffered code/value pairs for a POLYLINE
 *
 * @return seg {Segment}: a new segment.
 *
 * returned null segment indicates the layername is not prepended to indicate a MC segment
 * TODO: return null if an ASHADE entity, or if you intend to only read 3DFACES
 *
 */
function readRegPlineBuffer(b) {
	// trim down the buffer to eliminate insert point at top before VERTEX's
	var orig_lines = b.split('\n');
	var new_buffer = '';
	var firstVertFound = false;
	let code = -1;
	let value = '';

	for (let l = 0; l < orig_lines.length; l++) {
		const line = orig_lines[l].split(';');
		code = parseInt(line[0]);
		value = line[1];

		if (value === 'VERTEX') firstVertFound = true;

		if (firstVertFound) {
			new_buffer += code + ';' + value + '\n';
		} else if (code != 10 && code != 20 && code != 30) {
			new_buffer += code + ';' + value + '\n';
		}
	}

	// now new_buffer reflects a compact version of b
	const seg = new Segment(0, 'SEED_SEGMENT', 40);
	seg.geometry = new THREE.Geometry();
	const pt = [0, 0, 0];

	var new_lines = new_buffer.split('\n');
	for (let l = 0; l < new_lines.length; l++) {
		const line = new_lines[l].split(';');
		code = parseInt(line[0]);
		value = line[1];

		switch (code) {
			case 8:
				if (value.startsWith('@gb_')) {
					seg.id = value.substring(2);
				} else return null;
				break;
			// deprecated: doesn't work in TrueView but retained for backward compatibility
			case 1011:
				seg.minHeight = +value;
				break;
			// preferred way to transmit minHeight or other MC/AltGen values
			case 999:
				const comment = value.split(':');
				const mc_code = comment[0];
				if (mc_code === 'minHeight')
					seg.minHeight = parseFloat(comment[1]);
				console.log('999 parsing: ', comment, seg.minHeight);
				break;
			case 62:
				seg.color = acadColorToRGB(parseInt(value));
				break;
			case 10:
				pt[0] = +value;
				break;
			case 20:
				pt[1] = +value;
				break;
			case 30:
				pt[2] = +value;
				// 30's always seem to come last, potential for bugs here...
				var point = new THREE.Vector3(pt[0], pt[1], pt[2]);
				if (seg.id !== 'RECORDED') seg.geometry.vertices.push(point);
				break;
		}
	}
	return seg;
}

/**
 * processes the stored buffer values in the context of reading an 3DFACE
 *
 * @param  b {string}: buffered code/value pairs for an LWPOLYLINE
 *
 * @return seg {Segment}: a new segment.
 *
 *         returned null segment indicates not valid for adding to segmentlist
 *
 */
function read3DFaceBuffer(b) {
	let f = new Face('Noah', 0, [0, 0, 0], [1, 1, 1], [2, 2, 2], [3, 3, 3]);

	var lines = b.split('\n');
	const seg = new Segment(0, 'SEED_SEGMENT', 40);
	seg.geometry = new THREE.Geometry();
	const pt = [0, 0, 0];

	for (let l = 0; l < lines.length; l++) {
		const line = lines[l].split(';');
		const code = parseInt(line[0]);
		const value = line[1];

		switch (code) {
			case 8:
				// note all 3DFACEs should be read, at least for now
				f.id = value.substring(2);
				break;
			case 62:
				f.color = acadColorToRGB(parseInt(value));
				break;

			case 10:
				f.pt0[0] = +value;
				break;
			case 20:
				f.pt0[1] = +value;
				break;
			case 30:
				f.pt0[2] = +value;
				break;

			case 11:
				f.pt1[0] = +value;
				break;
			case 21:
				f.pt1[1] = +value;
				break;
			case 31:
				f.pt1[2] = +value;
				break;

			case 12:
				f.pt2[0] = +value;
				break;
			case 22:
				f.pt2[1] = +value;
				break;
			case 32:
				f.pt2[2] = +value;
				break;

			// break point?
			case 13:
				f.pt3[0] = +value;
				break;
			case 23:
				f.pt3[1] = +value;
				break;
			case 33:
				f.pt3[2] = +value;
				break;
		}
	}

	if (f.pt2[0] < 10) {
		console.log('read3DFaceBuffer suspicious face:', f);
		console.log('from suspicious buffer:', b);
	}

	if (facelist[0].id === 'EMPTY_FACELIST') {
		facelist[0] = f;
	} else {
		facelist.push(f);
	}
}

/**
 * scans DXF data and extracts Massing Configurator segments from it
 *
 * @param {any} r : string representing a DXF file
 *
 * @return segment[]
 */
function readDxfGeometry(r) {
	var code;
	var value;
	var pt;
	pt = [0, 0, 0];
	var lines = r.split('\n');
	var startSegments = true;
	var entitiesStartLine = 0;
	var segs = new Array(Segment);

	// read down to beginning of ENTITIES section
	for (var line = 0; line < lines.length && entitiesStartLine === 0; line++) {
		code = parseInt(lines[line]);
		line++;
		value = lines[line];
		if (code === 2 && eqStr(value, 'ENTITIES')) {
			// beginning of entities section read
			entitiesStartLine = line + 1;
		}
	}

	var readingEntity = '';
	// var firstVert = false;
	// var startVerts = false;
	var segment = new Segment(0, 'temp', 40);
	var heightValue = 38;
	var entityBuffer = '';
	segment.id = 'RECORDED'; // indicates a copy of this segment has been pushed to segs already

	// ********************************************************
	// read through the file and extract segment coordinates...
	for (var line = entitiesStartLine; line < lines.length; line++) {
		code = parseInt(lines[line]);
		line++;
		value = lines[line].trim();

		if (code === 0) {
			// here you write the current stored buffer out according to the existing reading parameter
			// note the case values below correspond only to supported data types and their sub-types...
			switch (readingEntity) {
				// note readingEntity at this point should refer to what was just read and in the entityBuffer
				// readingEntity will be reset _after_ this switch statement
				case 'LWPOLYLINE':
					var newSeg = readLWPlineBuffer(entityBuffer);
					if (newSeg) {
						if (newSeg.id != 'SEED_SEGMENT') {
							if (startSegments) {
								segs = [newSeg];
								startSegments = false;
							} else segs.push(newSeg);
						}
					}
					entityBuffer = '';
					break;

				case 'SEQEND':
					var newSeg = readRegPlineBuffer(entityBuffer);
					if (newSeg) {
						if (newSeg.id != 'SEED_SEGMENT') {
							if (startSegments) {
								segs = [newSeg];
								startSegments = false;
							} else segs.push(newSeg);
						}
					}
					entityBuffer = '';
					break;

				case '3DFACE':
					read3DFaceBuffer(entityBuffer);
					entityBuffer = '';
					break;
			} // switch based on readingEntity

			// readingEntity = '';

			// here you set the new reading parameters based on the 0 value just read
			switch (value) {
				case 'LWPOLYLINE':
					readingEntity = 'LWPOLYLINE';
					break;

				case 'POLYLINE':
					readingEntity = 'POLYLINE';
					break;

				case 'SEQEND':
					// note: SEQEND signals the end of a vert list in a POLYGON
					readingEntity = 'SEQEND';
					break;

				case 'VERTEX':
					// note: do not change readingEntity for a Vertex, which is a sub-entity
					break;

				case '3DFACE':
					readingEntity = '3DFACE';
					break;

				case 'INSERT':
					readingEntity = 'INSERT';
					break;

				case 'ENDSEC':
					readingEntity = 'ENDSEC';
					break;

				default:
					readingEntity = 'UNSUPPORTED';
			} // switch based on value
		} // end if code === 0

		// If you are reading within a readable entity, load the buffer with another code/value pair
		let readableEntities = ['LWPOLYLINE', 'POLYLINE', '3DFACE'];
		if (readableEntities.includes(readingEntity)) {
			entityBuffer += code + ';';
			entityBuffer += value + '\n';
		}

		// exit loop if you encounter EOF or ENDSEC
		if (eqStr(value, 'EOF') || eqStr(value, 'ENDSEC')) line = lines.length;
	} // for each line in file
	// end of reading file and extracting segment coordinates...
	// ********************************************************

	// you have come to the end of reading the file
	return { seglist: segs, facelist: facelist };
} // end readDxfGeometry()

function acadColorToRGB(acadColor) {
	// map AutoCAD index value to their RGB equivalents
	if (Number(acadColor) === 0) return 0x000000;
	if (Number(acadColor) === 1) return 0xff0000;
	if (Number(acadColor) === 2) return 0xffff00;
	if (Number(acadColor) === 3) return 0x00ff00;
	if (Number(acadColor) === 4) return 0x00ffff;
	if (Number(acadColor) === 5) return 0x0000ff;
	if (Number(acadColor) === 6) return 0xff00ff;
	if (Number(acadColor) === 7) return 0xffffff;
	if (Number(acadColor) === 8) return 0x414141;
	if (Number(acadColor) === 9) return 0x808080;

	// if not first simple numbers, assign a default value:
	return 0x0000aa;
}

function rgbColorToAcad(rgbColor) {
	// map RGB color to its AutoCAD equivalents
	switch (rgbColor) {
		case 0x000000:
			return 0;
		case 0xff0000:
			return 1;
		case 0xffff00:
			return 2;
		case 0x00ff00:
			return 3;
		case 0x00ffff:
			return 4;
		case 0x0000ff:
			return 5;
		case 0xff00ff:
			return 6;
		case 0xffffff:
			return 7;
		case 0x414141:
			return 8;
		case 0x808080:
			return 9;
	}

	// if not first simple numbers, assign a default value:
	return 100;
}

// write a line to a text file with a line return at the end
function dxfLine(str) {
	return (
		str +
		`
`
	);
}

/**
 * converts a segment to a regular DXF Polyline
 *
 * @param {any} seg : segment object
 *              index: not used
 *
 * @return str: string describing a regular DXF polyline, with MC values embedded
 */
function segToPolyline(seg, index) {
	// does this have to be Parcel?
	const layername = '@g' + seg.id;
	// const layername = "Parcel";

	// start a new polyline
	let str = dxfLine('0');
	str += dxfLine('POLYLINE');
	str += dxfLine('999');
	str += dxfLine('minHeight:' + seg.minHeight);
	str += dxfLine('8');
	str += dxfLine(layername);

	str += dxfLine('66');
	str += dxfLine('1');
	str += dxfLine('70');
	str += dxfLine('1');
	str += dxfLine('10');
	str += dxfLine('0');
	str += dxfLine('20');
	str += dxfLine('0');
	str += dxfLine('30');
	str += dxfLine('0');

	str += dxfLine('62');
	str += dxfLine(rgbColorToAcad(seg.color));

	// write each of the verts
	for (var i = 0; i < seg.geometry.vertices.length; i++) {
		str += dxfLine('0');
		str += dxfLine('VERTEX');
		str += dxfLine('8');
		str += dxfLine(layername);
		str += dxfLine('10');
		str += dxfLine(seg.geometry.vertices[i].x);
		str += dxfLine('20');
		str += dxfLine(seg.geometry.vertices[i].y);
		str += dxfLine('30');
		str += dxfLine('55');
	}

	// end the polyline
	str += dxfLine('0');
	str += dxfLine('SEQEND');

	return str;
}

/**
 * converts a segment to a DXF LWPolyline
 *
 * @param {any} seg : segment object
 *              index: not used
 *
 * @return str: string describing a DXF LWpolyline, with MC values embedded
 */
function segToLwpolyline(seg, index) {
	const layername = '@g' + seg.id;

	let str = dxfLine('0');
	str += dxfLine('LWPOLYLINE');

	str += dxfLine('8');
	str += dxfLine(layername);
	str += dxfLine('90');
	str += dxfLine(seg.geometry.vertices.length);
	str += dxfLine('62');
	str += dxfLine(rgbColorToAcad(seg.color));

	// must write as a 999 comment, see segToPolyline
	str += dxfLine('1011');
	str += dxfLine(seg.minHeight);

	str += dxfLine('38');
	str += dxfLine(seg.geometry.vertices[0].z);

	for (var i = 0; i < seg.geometry.vertices.length; i++) {
		str += dxfLine('10');
		str += dxfLine(seg.geometry.vertices[i].x);
		str += dxfLine('20');
		str += dxfLine(seg.geometry.vertices[i].y);
	}

	return str;
}

/**
 * converts a face to a DXF 3DFACE
 *
 * @param {any} face : face object
 *
 * @return str: string describing a DXF 3DFACE,
 *
 */
function faceTo3dFaces(face) {
	let str = '';

	str += dxfLine('0');
	str += dxfLine('3DFACE');
	str += dxfLine('8');
	str += dxfLine(face.id);
	str += dxfLine('62');
	str += dxfLine(face.color);

	// first vertex
	str += dxfLine('10');
	str += dxfLine(face.pt0[0]);
	str += dxfLine('20');
	str += dxfLine(face.pt0[1]);
	str += dxfLine('30');
	str += dxfLine(face.pt0[2]);

	// second vertex
	str += dxfLine('11');
	str += dxfLine(face.pt1[0]);
	str += dxfLine('21');
	str += dxfLine(face.pt1[1]);
	str += dxfLine('31');
	str += dxfLine(face.pt1[2]);

	// third vertex
	str += dxfLine('12');
	str += dxfLine(face.pt2[0]);
	str += dxfLine('22');
	str += dxfLine(face.pt2[1]);
	str += dxfLine('32');
	str += dxfLine(face.pt2[2]);

	// fourth vertex
	str += dxfLine('13');
	str += dxfLine(face.pt3[0]);
	str += dxfLine('23');
	str += dxfLine(face.pt3[1]);
	str += dxfLine('33');
	str += dxfLine(face.pt3[2]);

	if (face.pt3[2] < 10) {
		console.log('suspicious face:', face);
	}

	return str;
}

/**
 * converts a segment to a DXF 3DFACE
 *
 * @param {any} seg : segment object
 *
 * @return str: string describing a DXF 3DFACE,
 * with new layername (@fc_[oldlayername]) that identifies faces as fastcast output
 */
function segTo3dFaces(seg) {
	let str = '';
	const layerColor = rgbColorToAcad(seg.color);

	// the layername is written ignoring the original b_ prefix:
	const layerName = '@fc_' + seg.id.substring(2);

	let j = 0;
	let newHeight = 0;

	for (var i = 0; i < seg.geometry.vertices.length; i++) {
		j = i + 1;
		if (j === seg.geometry.vertices.length) j = 0;

		newHeight = seg.geometry.vertices[i].z + seg.minHeight;

		str += dxfLine('0');
		str += dxfLine('3DFACE');
		str += dxfLine('8');
		str += dxfLine(layerName);
		str += dxfLine('62');
		str += dxfLine(layerColor);

		// first vertex at i location
		str += dxfLine('10');
		str += dxfLine(seg.geometry.vertices[i].x);
		str += dxfLine('20');
		str += dxfLine(seg.geometry.vertices[i].y);
		str += dxfLine('30');
		str += dxfLine(seg.geometry.vertices[i].z);

		// second vertex at j location
		str += dxfLine('11');
		str += dxfLine(seg.geometry.vertices[j].x);
		str += dxfLine('21');
		str += dxfLine(seg.geometry.vertices[j].y);
		str += dxfLine('31');
		str += dxfLine(seg.geometry.vertices[j].z);

		// third vertex at j location plus height
		str += dxfLine('12');
		str += dxfLine(seg.geometry.vertices[j].x);
		str += dxfLine('22');
		str += dxfLine(seg.geometry.vertices[j].y);
		str += dxfLine('32');
		str += dxfLine(newHeight);

		// fourth vertex at i location plus height
		str += dxfLine('13');
		str += dxfLine(seg.geometry.vertices[i].x);
		str += dxfLine('23');
		str += dxfLine(seg.geometry.vertices[i].y);
		str += dxfLine('33');
		str += dxfLine(newHeight);
	} // end of i loop

	return str;
}

// return the top portion (above ENTITIES section) of a DXF file
function dxfHeader() {
	return `0
SECTION
2
ENTITIES
`;
}

// end the current section and return the end of a DXF file
function dxfFooter() {
	return `0
ENDSEC
0
EOF
`;
}

/**
 * Expresses SegmentList data in DXF POLYINE format, that is readable by MC and AutoCAD
 *
 * @param segL {SegmentList} : list of Segment's
 *
 * @return dxf : a string in DXF format
 */
function segmentListToPolylines(segL) {
	let dxf = '';

	if (segL[0].id != 'EMPTY_SEGLIST') {
		for (var i = 0; i < segL.length; i++) {
			dxf += segToPolyline(segL[i]);
		}
	}
	return dxf;
}

/**
 * Expresses SegmentList data in DXF 3DFACE format, that is readable by MC, fastcast and AutoCAD
 *
 * @param segL {SegmentList} : list of Segment's
 *
 * @return dxf : a string in DXF format
 */
function segmentListToFaces(segL) {
	let dxf = '';

	// output all faces implied by SegList...
	// if (segL[0].id != 'EMPTY_SEGLIST') {
	for (var i = 0; i < segL.length; i++) {
		dxf += segTo3dFaces(segL[i]);
	}
	// }
	return dxf;
}

/**
 * Expresses FaceList data in DXF 3DFACE format, that is readable by MC, AutoCAD and fastcast
 * This allows user to download the exact same DXF string that will be sent to fastcast, so that she can review
 *
 * @param faceL {FaceList} : list of faces
 *
 * @return dxf : a string in DXF format
 */
function faceListToFaces(faceL) {
	let dxf = '';

	// output any faces held in facelist
	for (let i = 0; i < faceL.length; i++) {
		dxf += faceTo3dFaces(faceL[i]);
	}
	return dxf;
}

/**
 * downloads a string as a text file to the browser's local drive
 *
 * @param filename {string}: name of the new file to create
 *
 * @param str {string}: content of the file
 *
 * @return dxf : a string in DXF format
 */
function downloadAsTextFile(filename, str) {
	var outputFile = document.createElement('a');
	outputFile.setAttribute(
		'href',
		'data:text/plain;charset=utf-8,' + encodeURIComponent(str)
	);
	outputFile.setAttribute('download', filename);

	outputFile.style.display = 'none';
	document.body.appendChild(outputFile);

	outputFile.click();

	document.body.removeChild(outputFile);
}

export {
	// eqStr,
	readDxfGeometry,
	segmentListToPolylines,
	segmentListToFaces,
	dxfHeader,
	dxfFooter,
	faceListToFaces,
	downloadAsTextFile
};
