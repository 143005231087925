// Copyright 2020 Noah Kennedy Larkspur CA.

/**
 * @fileoverview Segment List Item component: individual building element listing in Massing Configurator
 *
 * @author noahskennedy@gmail.com (Noah Kennedy)
 */

import React, { Component } from 'react';

class SegmentListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.height,
      editable: this.props.editable,
      hideButton: true,
      styleString: { backgroundColor: 'white' }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
      styleString: { backgroundColor: 'yellow' },
      hideButton: false
    });
  }

  handleSubmit(event) {
    this.props.onNewHeight(this.props.index, this.state.value);
    this.setState({
      styleString: { backgroundColor: 'white' },
      hideButton: true
    });
    event.preventDefault();
  }

  // variable that allows for different rending of item in read-only mode
  inputClassString = 'mc-seg-height-value pull-right';

  hideableItemString = 'd-flex flex-row flex-wrap';

  render() {
    if (!this.props.editable) {
      this.inputClassString = 'mc-seg-height-value-readonly pull-right';
    }


    return (
      <React.Fragment>
        <div hidden={this.props.id === "EMPTY_SEGLIST"}>

          <div id='mc-seg-list-item' className={this.hideableItemString} >
            <div className=' mc-seg-id'>
              <i>{this.props.id.substring(2)}</i>
            </div>

            <form onSubmit={this.handleSubmit}
              name={this.props.id}
              id='mc-seg-height'
              className='d-flex'
            >
              <input
                style={this.state.styleString}
                type="number"
                className={this.inputClassString}
                min='0'
                id={this.props.id}
                name={this.props.id.substring(2)}
                value={this.state.value}
                onChange={
                  this.handleChange
                }
                disabled={!this.props.editable}
              />

              <button
                hidden={this.state.hideButton}
                type="submit"
              >&#8634;</button>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SegmentListItem;
